// USA
export const locale = {
	lang: 'en',
	data: {
		"ckeywords": {
			"customer": "Customer",
			"scustomer": "Select Customer",
			"amount": "Amount",
			"data": "Data",
			"t5users": "Top 5 Users",
			"inumber": "Invoice Number",
			"inumber1": "Invoice Number:",
			"ucount": "User Count:",
			"ucount1": "Total User Count",
			"mucount": "Mobile User Count",
			"invoiceamt": "Invoice Amount",
			"export": "Export",
			"import": "Import",
			"operator": "Operator",
			"cplan": "Current Plan",
			"cuamount": "Current Usage Amount (€)",
			"opamount": "Optimized Plan Amount (€)",
			"samount": "Saving Amount (€)",
			"bccenter": "By Cost Center",
			"ccenter": "Cost Center",
			"usummaries": "Optimizable Usages Summaries",
			"plantype": "Plan Type",
			"cperson": "Contact Person",
			"eid": "Email ID",
			"country": "Country",
			"action": "Action",
			"anew": "Add New",
			"fmamandatory": "Field(s) marked as '*' are mandatory.",
			"eiirequired": "Email ID is required",
			"save": "Save",
			"cancel": "Cancel",
			"iactive": "Is Active",
			"soperator": "Select Operator",
			"cgname": "Cost Group Name",
			"ename": "Enter Name",
			"ctype": "Category Type",
			"tbskipped": "To Be Skipped",
			"no": "No",
			"pgroup": "Plan Group",
			"yes": "Yes",
			"search": "Search",
			"uname": "User Name",
			"pnumber": "Phone Number",
			"reset": "Reset",
			"celement": "Cost Element",
			"euros": "Euros",
			"reports": "Reports",
			"eorganization": "Entire Organization",
			"masters": "Masters",
			"sccenter": "Select Cost Center",
			"samonth": "Select Month",
			"epnumber": "Enter Phone Number",
			"pnirequired": "Phone Number is required",
			"pniinvalid": "Phone Number is invalid",
			"eiiinvalid": "Email ID is invalid",
			"ccirequired": "Cost Country is required",
			"edit": "Edit",
			"cconfirm": "Are you sure to cancel?",
			"required": "{{name}} is required",
			"invalid": "{{name}} is invalid!",
			"delete": "Delete",
			"dconfirm": "Are you sure you want to delete?",
			"cirequired": "Country is required",
			"scountry": "Select Country",
			"ccategory": "Cost Category",
			"sidentified": "Savings Identified",
			"language": "Language",
			"mprofile": "My Profile",
			"cpassword": "Change Password",
			"sout": "Sign Out",
			"ddate": "Due Date",
			"rnumber": "Reference Number",
			"bplan": "Billing Plan",
			"ppattern": "Required at least six characters, one upper case letter, one lower case letter, one number and one special character Ex: Pass@123",
			"ecperson": "Enter Contact Person",
			"dnavailable": "Data not available",
			"company": "Company",
			"scompany": "Select Company",
			"subcompany": "Sub-Company",
			"ssubcompany": "Select Sub-Company",
			"month": "Month",
			"smonth": "Select Month",
			"bplangrp": "Base Plan Group",
			"cmasters": "Client Masters",
			"all": "All",
			"unamemail": "Username / Email ID",
			"scostcenter": "Select Cost Center",
			"cprequired": "Contact Person is required!",
			"contactno": "Contact No.",
			"econtactno": "Enter Contact No",
			"rcontactno": "Contact Number is required!",
			"icontactno": "Contact Number is invalid!",
			"email": "Email",
			"eemail": "Enter Email",
			"remail": "Email is required!",
			"iemail": "Email is invalid!",
			"back": "Back",
			"name": "Name",
			"status": "Status",
			"submit": "Submit",
			"addNewOperator": "Add New Operator",
			"exportAllData": "Export All Data",
			"exportOptList": "Export Optimization List",
			"contractaction": "Action",
			"saveAll":"Save All"

		},

		"message": {
			"sregister": "Registered Successfully",
			"eregister": "Either of your fields (Company Name, Business ID, Email ID) are already in use!",
			"ivalidContract":"Currently disabled. Please add valid contract first!",
			"clienterminatedval":"Currently disable, As Client current status is terminated.",
			"caddsuccess": "Client added successfully!",
			"cadderror": "Either of your fields (Business ID, BidGate ID, Company Name, Email ID) are already exists!",
			"sprofileedit": "Profile updated successfully!",
			"eprofileedit": "Either of your fields (Email ID,Company Name) are already exists!",
			"srecordsave": "Record saved successfully!",
			"errorinsave": "Error in record save!",
			"subcompanyedit":"Sub-Company updated successfully!",
			"subcompanyduplicate":"Sub-Company name is already exists!",
			"cudeletesuccess":"Client user deleted successfully!",
			"cudeleteerror":"Error in Client user delete!",			
			"cuseraddError":"Email ID is already exists!",		
			"scontract": "Contract Added Successfully!",
			"sdcontract": "Contract Saved as Draft Successfully!",
			"econtract": "Contract Updated Successfully!",
			"stcontract":"Contract Terminated Successfully!",		
			"sresetpassword": "Password Reset Successfully!",
			"eresetpassword": "Error Occurred!",
			"sctoCompany": "Lead Converted to Company Successfully!",
			"ectoCompany": "Error in Convert To Company!",
			"ccdetailssuccess": "Operator Contract Details Added Successfully!",
			"ccdetailsedit": "Operator Contract Details Updated Successfully!",
			"ccdetailserror": "Error Occurred!",
			"duploadsuccess": "Document uploaded successfully!",
			"duploadConflict": "Document with same name is already uploaded!",
			"duploaderror": "Something went wrong.",
			"docdelete": "Document Deleted Successfully!",
			"docerror": "Error in Document Deletion!",
			"shelp": "File saved successfully!",
			"fhelp": "Failed",
			"sbillingplan": "Client billing plan added successfully!",
			"ubillingplan": "Client billing plan updated successfully!",
			"abillingplan": "Client billing plan with same name is already exists!",
			"dbillingplan": "Client billing plan deleted successfully!",
			"edbillingplan":"Client billing plan is in use, hence cannot be deleted!",
			"error": "Error!",
			"cpaddsuccess": "Client Payment added successfully.",
			"paymentamountval":"Amount should be greater than 0",
			"condemandsupaddsuccess": "Client OnDemand Support added successfully.",
			"ondemandhourval":"Hours should be greater than 0",
		},

		"customer": {
			"cdashboard": {
				"dashboard": "Dashboard",
				"costAndUsage":"Cost And Usage",
				"cdashboard": "Customer Dashboard",
				"utype": "Usage Type",
				"sutype": "Select Usage Type",
				"all": "All",
				"minutes": "Minutes",
				"sms": "SMS",
				"tisms": "Total Invoice SMS",
				"timinutes": "Total Invoice Minutes",
				"timinutes1": "Total Invoice Minutes",
				"tiamount": "Total Invoice Amount",
				"tgraph": "Trend Graph",
				"ustatistics": "Usage Statistics",
				"message": "Messages",
				"duration": "Duration",
				"units": "Units",
				"months": "Months",
				"pnumber": "Phone Number",
				"name": "Name",
				"tidata": "Total Invoice Data",
				"period": "Period",
				"speriod": "Select Period",
				"subcompany": "Sub-Company",
				"ssubcompany": "Select Sub-Company",
				"costcategory": "Cost Category",
				"scostcategory": "Select Cost-Category",
				"mfees": "Monthly Fees",
				"calls": "Calls",
				"data": "Data",
				"cost": "Traffic Cost",
				"tmsg": "Total Messages",
				"tcall": "Total Calls",
				"tdata": "Total Data",
				"tmfee": "Total Monthly Fees",
				"ttcost": "Total Traffic Cost",
				"tcost": "Total Costs",
			},

			"cDashboard_2":{
				"dashboard_2":"Savings",
				"totalNumberOfPlans":"Total Number Of Plans (Kpl)",
				"changeInNumberOfPlans":"Changes In Number Of Plans (Kpl)",
				"totalCostWithOptimization":"Total Cost With Optimization (€)",
				"totalCostWithoutOptimization":"Total Cost Without Optimization (€)",
				"totalSavings":"Total Savings (€)",
				"numberOfPlans":"Number Of Plans",
				"spendAndSavings":"Spend and Savings",
				"months":"MONTHS",
				"chartNumberOfPlans":"NUMBER OF PLANS",
				"cost":"COST",
				"costWithOptimization":"Cost with Optimization",
				"costWithoutOptimization":"Cost Without Optimization",
				"savings":"Savings"
			},

			"uinvoice": {
				"uinvoice": "Upload Invoice",
				"cfile": "Choose File",
				"nfchoosen": "No file chosen",
				"updf": "Upload PDF",
				"usummary": "Upload Summary",
				"itable": "Invoice Table",
				"idate": "Invoice Date :",
				"pnumber": "Phone Number",
				"name": "Name",
				"dinvoice": "Download Invoice",
				"cname": "Company Name",
				"sms": "SMS",
				"minutes": "Minutes",
				"items": "items",
				"page": "page",
				"goto": "Goto",
				"pspftupload": "Please select PDF file to upload",
				"processing": "processing",
				"hon": "Hold On!",
				"llyprhncipyet": "Looks like your previous request has not completed its parsing yet",
				"wwnyiymowadone": "We will notify you in your mailbox once we are done",
				"nuftmonth": "No uploads for this month",
				"tiahbparsed": "This invoice already has been parsed",
				"Tfinvinvoice": "This file is not valid invoice!",
				"tyou": "Thank You",
				"wnext": "What next?",
				"patndfuimtawhile": "Pulling all the necessary details from uploaded invoice might take a while.",
				"bitbsoiyjuploaded": "Below is the basic summary of invoice you just uploaded:",
				"iparsed": "Is parsed",
				"scname": "Sub-Company Name",
				"scname1": "Sub-Company Name:",
				"idate1": "Invoice Date",
				"sfiles": "Select Files",
				"dfzone": "Drop File Zone",
				"ufiles": "Upload Files",
				"size": "Size",
				"actions": "Actions",
				"uall": "Upload All"
			},

			"cseowise": {
				"csummaries": "Optimizable Cost Summaries",
				"eorganizationwise": "Entire Organization Wise",
				"month": "Month",
				"smonth": "Select Month",
				"name": "Name",
				"pnumber": "Phone Number",
				"npidentified": "New Plan Identified",
				"optimized": "Optimized",
				"items": "items",
				"page": "page",
				"goto": "Goto"
			},

			"csbccenter": {
				"csummaries": "Optimizable Cost Summaries",
				"month": "Month",
				"smonth": "Select Month",
				"name": "Name",
				"pnumber": "Phone Number",
				"npidentified": "New Plan Identified",
				"optimized": "Optimized",
				"items": "items",
				"page": "page",
				"goto": "Goto",
				"famount": "Forecasted Amount"
			},

			"useowise": {
				"eowise": "Entire Organization Wise",
				"month": "Month",
				"smonth": "Select Month",
				"name": "Name",
				"pnumber": "Phone Number",
				"sms": "SMS",
				"minutes": "Minutes",
				"items": "items",
				"page": "page",
				"goto": "Goto"
			},

			"usbccenter": {
				"month": "Month",
				"smonth": "Select Month",
				"sccenter": "Select Cost Center",
				"name": "Name",
				"pnumber": "Phone Number",
				"sms": "SMS",
				"esms": "EU SMS",
				"minutes": "Minutes",
				"eminutes": "EU Minutes",
				"edata": "EU Data (MB)",
				"items": "items",
				"page": "page",
				"goto": "Goto"
			},

			"topusersreport": {
				"tusers": "Top Users",
				"tuserc": "Top Users By Cost",
				"minutes": "Minutes",
				"sms": "SMS",
				"pnumber": "Phone Number",
				"costcategory": "Cost Category",
				"scostcategory": "Select Cost-Category",
				"sooptcost": "Show Only Optimizable Costs"
			},

			"overchargedplansreport": {
				"overchargedplans": "Overcharged Plans"
			},

			"unUsedMonthCount": {
				"unusedMonthCountTitle": "Unused Month Count",
				
			},

			"umanagement": {
				"umanagement": "Invoice User Management",
				"name": "Name",
				"pnumber": "Phone Number",
				"alogin": "Allow Login",
				"edit": "Edit",
				"delete": "Delete",
				"items": "items",
				"page": "page",
				"goto": "Goto",
				"unirequired": "User name is required!",
				"pniinvalid": "Phone Number is invalid",
				"pnirequired": "Phone Number is required",
				"eiirequired": "Email ID is required!",
				"eiiinvalid": "Email  ID is invalid",
				"eeid": "Enter Email ID",
				"euname": "Enter User Name", //need to varify
				"mbenifit": "Mobile Benefits",
				"5gBenifit": "5G Plan",
				"importusers":"Import Users"
			},

			"pdetails": {
				"pdetails": "Plan Details",
				"pgroups": "Plan Groups",
				"mfoptimization": "Mark for Optimization",
				"vplans": "Voice Plans",
				"pname": "Plan Name",
				"cprice": "Contract Price (€)",
				"SMSlprice": "SMS List Price (€)",
				"SMScprice": "SMS Contract Price (€)",
				"minlprice": "Voice List Price (€)",
				"mincprice": "Voice Contract Price (€)",
				"considered": "Considered",
				"dslab": "Data Slab (MB)",
				"edit": "Edit",
				"sms": "SMS",
				"minutes": "Minutes",
				"ipunlimited": "Is Plan Unlimited",
				"lprice": "List Price (€)",
				"cfoptimization": "Consider for Optimization ",
				"dplan": "Domestic Plan",
				"ptname": "Plan Type Name",
				"saoplans": "SMS Add-On Plans",
				"mbplans": "Mobile BroadBand Plans",
				"m2mplans": "M2M Plans",
				"gplangroup": "General Plan Group",
				"csplangroup": "Customer Specific Plan Group",
				"dataMbits":"Data Mbits/s"

			},

			"epdetails": {
				"epdetails": "Edit Plan Details",
				"pname": "Plan Name",
				"lprice": "List Price (€)",
				"cprice": "Contract Price (€) ",
				"SMSlprice": "SMS List Price (€)",
				"SMScprice": "SMS Contract Price (€)",
				"minlprice": "Voice List Price (€)",
				"mincprice": "Voice Contract Price (€)",
				"tbconsidered": "To Be Considered",
				"dslab": "Data Slab (MB)",
				"ecprice": "Enter Contract Price",
				"eSMScprice": "Enter SMS Contract Price",
				"emincprice": "Enter Voice Contract Price",
				"edslab": "Enter Data Slab",
				"cpirequired": "Contract Price is required!",
				"dsirequired": "Data Slab is required!",
				"SMScpirequired": "SMS Contract Price is required!",
				"mincpirequired": "Voice Contract Price is required!",
			},

			"ostatistics":{
				"optimization": "Optimization",
				"ostatistics": "Optimization Statistics",
				"usdetails": "User Subscription Details",
				"idate": "Invoice Date",
				"ucount": "User Count",
				"ciamount": "Invoice Amount",
				"oucount": "Optimized User Count",
				"tovalue": "Total Optimized Amount",
				"roptimize": "Re-Optimize",
				"olist": "Optimized Plan",
				"boptimization": "Cost Before Optimization",
				"aoptimization": "Cost After Optimization",
				"savings": "Savings",
				"disconnectedSavings": "Savings if disconnected",
				"name": "Name",
				"pnumber": "Phone Number",
				"camount ": "Current Amount (€)",
				"ebplan": "Elisa's Best plan",
				"ebpamount": "Elisa's Best Plan Amount (€)",
				"uoplan": "Un-Optimized Plan",
				"moptimization": "Mark for Optimization",
				"camount": "Current Amount (€)",
				"elbplan": "Elisa's Best Plan",
				"uuplan": "Un-Used Plan",
				"mfoptimization": "Mark for Optimization",
				"ebpamount ": "Elisa's Best Plan Amount (€)",
				"ddetails": "Domestic Details",
				"originals": "Originals",
				"forecast": "Forecast",
				"considered": "Considered",
				"sms": "SMS",
				"minutes": "Minutes",
				"data": "Data",
				"ndetails": "Nordic/Baltic Details",
				"eudetails": "EU Details",
				"items": "items",
				"page": "page",
				"goto": "Goto",
				"dbplan": "DNA's best plan",
				"dbpamount": "DNA's best plan amount (€)",
				"tbplan": "Telia's Best Plan",
				"tbpamount": "Telia's Best Plan Amount (€)",
				"llyprhncipyet": "Looks like your previous request has not completed its parsing yet.",
				"tsoftipctadministrator.": "To start optimization for this Invoice, Please contact to Administrator.",
				"scname": "Sub-Company Name",
				"inumber": "Invoice Number",
				"cplan ": "Current plan ",
				"sLimit": "Saving Limit",
				"apply": "Apply"
			},

			"mprofile": {
				"mprofile": "My Profile",
				"username": "Username",
				"name": "Name",
				"cno": "Contact No",
				"update": "Update",
				"efname": "Enter Name",
				"eeid": "Enter Email ID",
				"epnumber": "Enter Phone Number",
				"scountry": "Select Country",
				"fnirequired": "Name is required!",
				"cnirequired": "Contact number is required!",
				"cirequired": "Country is required",
				"contractdetails":"Operator Contract Details",
				"accountdetails":"Account Details",
				"userdetails":"User Details",
				"settings":"Settings",
				"language":"Language",
				"slanguage":"Select Language",
				"uploadlogo":"Upload Company Logo",
				"allowfileformat":"Allowed file types: .png",
				"theme":"Application Theme",
				"stheme":"Select Application Theme",
			},

			"cpassword":{
				"cpassword": "Confirm Password",
				"opassword": "Old Password",
				"npassword": "New Password",
				"eopassword": "Enter Old Password",
				"enpassword": "Enter New Password",
				"ecpassword": "Enter Confirm Password",
				"opirequired": "Old Password is required",
				"npirequired": "New Password is required",
				"cpirequired": "Confirm Password is required",
				"opincorrect": "Old password is not correct!",
				"pdnmatch": "Password does not match"
			}
		},

		"admin": {

			"scheduleddownloads":{
				"downloads": "Downloads",
				"btnAllCostsandUsage": "All Costs and Usage",
				"btnOptimizableCostsandUsage": "Optimizable Costs and Usage",
				"download": "Download",
				"company": "Company",
				"ScheduledExportName": "Export Name",
				"Status": "Status",
				"action": "Action",
				"sheduledsuccessmsg": "Excel export has been successfully scheduled. We will notify you on your registered email.",
				"sheduledfailedmsg": "Failed to export excel. Please try after sometime.",
				"Scheduled": "Scheduled",
				"Ready": "Ready",
				"Failed": "Failed",
				"DashboardAllCostsandUsage": "All Costs and Usage",
				"DashboardOptimizableCostsandUsage": "Optimizable Costs and Usage",
				'BasePlaneTypes': 'Plan Types',
				'topUserReport': 'Top Users',
				"date": "Download date",
				"sdate": "Select Date",
				"ExecutiveReport":"Executive Summery Report"
			},
			
			"failedInvoice":{
				"failedInvoiceDownload":"Failed Invoice Download",
				"ClientId":"Client ID",
				"OperatorId":"Operator ID",
				"InvoiceNumber":"Invoice Number",
				"Action":"Action",
				"Client":"Client",
				"OperatoreName":"Operatore Name",
				"FailedDate":"Failed Date",
				"companyName":"Company Name"
			},

			"fullAutomation":{
				"fullAutomation":"Full Automation",

				"invoiceAutomation":"Invoice Automation",
				"invoiceManual":"Invoice Manual",
				"invoicePending":"Invoice Pending",
				"invoiceUploadedParsed":"Parsed/Uploaded (Selected Month)",
				"uncatagorisedCostElement":"Uncategorised Cost Elements",
				"scheduledOptimization":"Scheduled Optimization",

				"companyName":"Comany Name",
				"operatorName":"Operator Name",
				"adminname": "Admin Name",
				"automationType":"Automation Type",
				"automatic": "Automatic",
				"manual": "Manual",
				"lastUploadedDate": "Last Uploaded Date",
				"credentials":"Credentials",
				"available": "Available",
				"unavailable": "Unavailable",
				"credentialsStatus":"Credentials Status",
				"successful": "Successful",
				"failed": "Failed",

				"invoiceUploadReminderDate":"Invoice Upload Reminder Date",

				"optimizationCompleted":"Optimization Completed",
				"yes": "Yes",
				"no": "No",
				"downloadReports":"Download Reports",

				"operator": "Operator",
				"costGroup":"Cost Group",
				"costElement":"Cost Element",
				"invoiceNumber":"Invoice No",
				"costCatagory":"Cost Catagory",
				"createdOn":"Created On",
				"action":"Action",

				"subCompanyName":"Sub Company Name",
				"parsingStatus":"Parsing Status",
				"pending": "Pending",
				"completed": "Completed",
				"!!":"!!",

				"lastMonthInvoiceUploaded":"Last Month Invoice Uploaded",
				"lastUploadedInvoiceDate":"Last Uploaded Invoice Date"
			},

			"unusedMonthCount":{
				"unusedMonthCountTitle":"Unused subscriptions",
				"company":"Company",
				"invoiceNumber":"Invoice Number",
				"phoneNumber":"Phone Number",
				"userName":"User Name",
				"monthlyFees":"Monthly Fees (€)",
				"unUsedMonthCount":"Total unused months"
			},

			"activitylog":{
				"activitylog": "Activity Log",
				"date": "Date",
				"from": "From",
				"to": "To",
				"all": "All",
				"company": "Company",
				"consultant": "Consultant",
				"client": "Client",
				"clientPlaceHolder": "Select Clients",
				"module": "Module",
				"modulePlaceHolder": "Select Modules",
			},

			"consultantbilling":{
				"consultant": "Consultant",
				"consultantbilling": "Consultant Billing",
				"place_holder_consultant": "Select Consultant",
				"month": "Month",
				"ConsultantTotalBillingDetails": "Consultant Total Billing Details",
				"businessID": "Business ID",
				"BidgateID": "Bidgate ID",
				"CompanyName": "Company Name",
				"NoOfPlans": "No. Of Plans",
				"NoOfCustomerAdditionalUsers": "No. Of Customer Additional Users",
				"AutomatedInvoiceUpload": "Automated Invoice Upload",
				"IsNew": "Is New",
				"TotalNoofPlans": "Total No. of Plans",
				"NoOfConsultantAdditionalUsers": "No. of Consultant additional users",
				"TotalAutomatedInvoiceUpload": "Total Automated invoice upload",
				"NewlyAssignedCompanies": "Newly assigned companies/mo.",
				"TotalNoofCustomerAdditionalUsers": "Total No. of Customer additional users",
				"amount": "Amount (€)",
                "total":"Total (€)",
			},
			
			"dashboard": {
				"dashboard": "Dashboard",
				"costAndUsage": "Costs and Usage",
				"adashboard": "Admin Dashboard",
				"utype": "Usage Type",
				"sutype": "Select Usage Type",
				"all": "All",
				"optcost": "Optimizable Cost",
				"datal": "Data",
				"messagel": "Messages",
				"minutes": "Minutes",
				"sms": "SMS",
				"tisms": "Total Invoice SMS",
				"timinutes": "Total invoice Minutes",
				"tidata": "Total Invoice Data",
				"tiamount": "Total Invoice Amount",
				"tgraph": "Trend Graph",
				"ustatistics": "Usage Statistics",
				"message": "Messages",
				"duration": "Duration",
				"units": "Units",
				"months": "Months",
				"pnumber": "Phone Number",
				"name": "Name",
				"period": "Period",
				"speriod": "Select Period",
				"company": "Company",
				"scompany": "Select Company",
				"subcompany": "Sub-Company",
				"ssubcompany": "Select Sub-Company",
				"costcategory": "Cost Category",
				"scostcategory": "Select Cost-Category",
				"mfees": "Monthly Fees",
				"calls": "Calls",
				"data": "Data",
				"cost": "Traffic Cost",
				"tmsg": "Total Messages",
				"tcall": "Total Calls",
				"tdata": "Total Data",
				"tmfee": "Total Monthly Fees",
				"ttcost": "Total Traffic Cost",
				"tcost": "Total Costs",
				"costelement": "Cost Element",
				"scostelement": "Select Cost-Element"
			},

			"dashboard_2":{
				"dashboard_2":"Savings",
				"totalNumberOfPlans":"Total Number Of Plans (Kpl)",
				"changeInNumberOfPlans":"Changes In Number Of Plans (Kpl)",
				"totalCostWithOptimization":"Total Cost With Optimization (€)",
				"totalCostWithoutOptimization":"Total Cost Without Optimization (€)",
				"totalSavings":"Total Savings (€)",
				"numberOfPlans":"Number Of Plans",
				"spendAndSavings":"Spend and Savings",
				"months":"MONTHS",
				"chartNumberOfPlans":"NUMBER OF PLANS",
				"cost":"COST",
				"costWithOptimization":"Cost with Optimization",
				"costWithoutOptimization":"Cost Without Optimization",
				"savings":"Savings"
			},

			"uinvoice": {
				"uinvoice": "Upload Invoice",
				"cfile": "Choose File",
				"nfchoosen": "No File Chosen",
				"updf": "Upload PDF",
				"usummary": "Upload Summary",
				"idate": "Invoice Date:",
				"pnumber": "Phone Number",
				"name": "Name",
				"cname": "Company Name",
				"itable": "Invoice Table",
				"pstatus": "Parsing Status",
				"dinvoice": "Download Invoice",
				"sms": "SMS",
				"minutes": "Minutes",
				"pspftupload": "Please select PDF file to upload",
				"processing": "processing",
				"nuftmonth": "No uploads for this month.!",
				"tiahbparsed": "This invoice already has been parsed",
				"tfinvinvoice": "This file is not valid invoice",
				"Euros": "Euros",
				"eorganization": "Entire Organization",
				"customers": "Customers",
				"currency": "Currency",
				"scname": "Sub-Company Name",
				"scname1": "Sub-Company Name:",
				"idate1": "Invoice Date",
				"sfiles": "Select Files",
				"dfzone": "Drop File Zone",
				"ufiles": "Upload Files",
				"size": "Size",
				"actions": "Actions",
				"uall": "Upload All",
				"billingperiod": "Billing Period:",
				"umtitle": "File Upload Status",
				"uploaded": "Uploaded",
				"inprogress": "In-progress",
				"failed": "Failed"
			},

			"cseowise": {
				"csummaries": "Optimizable Cost Summaries",
				"eowise": "Entire Organization Wise",
				"month": "Month",
				"samonth": "Select Month",
				"name": "Name",
				"pnumber": "Phone Number",
				"npidentified": "New Plan Identified",
				"optimized": "Optimized",
				"sidentified": "Savings Identified",
				"sms": "SMS",
				"minutes": "Minutes"
			},

			"csbccenter":{
				"csummaries": "Optimizable Cost Summaries",
				"name": "Name",
				"pnumber": "Phone Number",
				"npidentified": "New Plan Identified",
				"optimized": "Optimized",
				"sidentified": "Savings Identified",
			},

			"useowise":{
				"eowise": "Entire Organization Wise",
				"name": "Name",
				"pnumber": "Phone Number",
				"sms": "SMS",
				"minutes": "Minutes"
			},

			"usbccenter":{
				"name": "Name",
				"pnumber": "Phone Number",
				"sms": "SMS",
				"minutes": "Minutes"
			},

			"plantypereport":{
				"plantype": "Base Plan Types",
				"month": "Month",
				"samonth": "Select Month",
				"cname": "Company",
				"scname": "Select Company",
				"sucname": "Sub-Company",
				"sasucname": "Select Sub-Company",
				"bplantype": "Base Plan Type",
				"nousers": "No of Users",
				"uname": "User Name",
				"pno": "Phone Number",
				"cp": "Current Plan",
			},

			"topusersreport":{
				"tusers": "Top Users",
				"tuserc": "Top Users By Cost",
				"minutes": "Minutes",
				"sms": "SMS",
				"pnumber": "Phone Number",
				"costcategory": "Cost Category",
				"scostcategory": "Select Cost-Category",
				"sooptcost": "Show Only Optimizable Costs"
			},

			"overchargedplansreport":{
				"overchargedplans": "Overcharged Plans",
				"tdifference": "Total Difference",
				"cplan": "Current Plan",
				"pcharged": "Price Charged",
				"cprice": "Contract Price",
				"difference": "Difference",	
			},

			"clientcontractsreport":{
				"clientcontracts": "Client Contracts",
				"acontracts": "Active Contracts",
				"inacontracts": "Inactive Contracts",
				"cname": "Company Name",
				"type": "Client Type",
				"bpname": "Billing Plan Name",
				"csdate": "Contract Start Date",
				"cedate": "Contract End Date",
				"bcycle": "Billing Cycle",
				"Atype" : "Automation Type"
			},

			"operators":{
				"operators": "Operators",
				"name": "Name",
				"pnumber": "Phone Number",
				"pniinvalid": "Phone Number is invalid",
				"eiiinvalid": "Email ID is invalid",
				"ccirequired": "Cost Country is required",
				"aystcancel": "Are you sure to cancel?",
				"eeid": "Enter Email ID",
			},

			"customers":{
				"customers": "Customers",
				"cname": "Customer Name ",
				"pnumber": "Phone Number"
			},

			"cacustomer":{
				"acustomer": " Add Customer",
				"cname": "Customer Name",
				"pnumber": "Phone Number",
				"password": "Password",
				"cpassword": "Confirm Password",
				"address": "Address",
				"ecperson": "Enter Contact Person",
				"epnumber": "Enter Phone Number ",
				"eeid": "Enter Email ID",
				"epassword": "Enter Password",
				"ecpassword": "Enter Confirm Password",
				"eaddress": "Enter Address",
				"cnirequired": "Customer Name is required",
				"pnirequired": "Phone Number is required",
				"pirequired": "Password is required",
				"cpirequired": "Confirm Password is required",
				"eiaexists": "Email ID already exists",
				"pcriteria": "Required at least six characters, one upper case letter, one lower case letter, one number and one special character Ex: Pass@123",
				"pacpdnmatch": "Password and confirm password does not match",
				"pniinvalid": "Phone Number is invalid",
				"ecname": "Enter Customer Name",
				"casuccessfully": "Customer added successfully"
			},

			"country":{
				"name": "Name",
				"ecname": "Enter Country Name",
				"nirequired": "Name is required"
			},

			"acountry":{
				"acountry": "Add country",
				"dywtatcountry": "Do you want to Activate this country?",
				"name": "Name",
				"ecname": "Enter Country Name",
				"nirequired": "Name is required",
			},

			"currency":{
				"currency": "Currency",
				"anew": "Add New",
				"name": "Name",
				"abbreviation": "Abbreviation",
				"symbol": "Symbol",
				"action": "Action",
				"cnirequired": "Currency Name is required",
				"cairequired": "Currency Abbreviation is required",
				"csirequired": "Currency Symbol is required"
			},

			"cacurrency":{
				"acurrency": "Add Currency",
				"cname": "Currency Name",
				"nirequired": "Name is required!",
				"cabbreviation": "Currency Abbreviation",
				"csymbol": "Currency Symbol",
				"dywtatcurrency": "Do you want to Activate this currency"
			},

			"ccenter":{
				"cname": "Customer Name",
				"ccname": "Cost Center Name"
			},

			"cgroup":{
				"cgroups": "Cost Groups",
				"cgroup": "Cost Group"
			},

			"cgacgroup":{
				"acgroup": "Add Cost Group",
				"name": "Name ",
				"nirequired": "Name is required",
				"iscplan": "Is Current Plan"
			},

			"ccategory":{
				"ccategory": "Cost Category",
				"ccname": "Cost Category Name",
				"utype": "Usage Type"
			},

			"ccaccategory":{
				"name": "Name ",
				"sms": "SMS",
				"voice": "Voice",
				"data": "Data",
				"mfee": "Monthly Fee",
				"accategory": "Add Cost Category",
				"eccategory": "Edit Cost Category",
				"oirequired": "Operator is required",
				"nirequired": "Name is required",
				"ename": "Enter Name",
				"domestic": "Domestic",
				"nordicbaltic": "NordicBaltic",
				"eu": "EU",
				"fname":"Finnish Name",
				"efname":"Enter Finnish Name",
				"fnirequired":"Suomenkielinen nimi vaaditaan",
			},

			"celements":{
				"celements": "Cost Element",
				"ccelements": "Categorized Cost Elements",
				"ucelements": "Uncategorized Cost Elements",
				"cename": "Cost Element Name",
				"con": "Created On",
				"scategory": "Select Category",
				"accelements": "Automatic Categorized Cost Elements",
				"approved":"Approved",
			},

			"plan":{
				"spgroup": "Select Plan Group",
				"plan": "Plan",
				"pname": "Plan Name",
				"price": "Price",
				"minutes": "Minutes",
				"coverage": "Coverage",
				"sms": "SMS",
				"ecpirequired": "Euro Cost Price is required",
				"mirequired": "Min is required",
				"sirequired": "SMS is required",
				"domestic": "Domestic",
				"nlimitations": "No Limitations",
				"pgroup": "Plan Group"
			},

			"paplan":{
				"pdetails": "Plan Details",
				"pgdetails": "Plan Group Details",
				"bconfiguration": "Basic Configuration",
				"name": "Name ",
				"bpcost": "Base Plan Cost/Price",
				"ptype": "Plan Type",
				"dslab": "Data Slab",
				"cfoptimization": "Consider for Optimization",
				"yes": "Yes ",
				"pcoverage": "Plan Coverage",
				"domestic": "Domestic",
				"nlimitations": "No Limitations",
				"sms": "SMS",
				"aplan": "Add Plan",
				"epdetails": "Edit Plan Details",
				"cprice": "Cost Price",
				"spgroups": "Select Plan Groups",
				"sptype": "Select Plan Type",
				"edslab": "Enter Data Slab",
				"vdetails": "Voice Details",
				"ddetails": "Domestic Details",
				"pplan": "Packet Plan",
				"5Gplan":"5G Plan",
				"psize": "Plan Size",
				"min": "Min(s)",
				"mcost": "Minute Cost",
				"amcost": "Additional Min Cost",
				"ndetails": "Nordic/Baltic Details",
				"edetails": "EU Details",
				"sdetails": "SMS Details",
				"scost": "SMS Cost",
				"ascost": "Additional SMS Cost",
				"saodetails": "SMS Add-On Details",
				"aosize": "Add-On Size",
				"aoecost": "Add-On Exceeding Cost",
				"datadetails": "DATA Details",
				"dtspeed": "Data Transfer Speed",
				"ipunlimited": "Is Plan Unlimited?",
				"edcost": "Exceeding Data Cost",
				"aopsize": "Add-On Packet Size",
				"aopcost": "Add-On Packet Cost",
				"dtpitduitnatbaltics": "Does the plan include the data usage in the Nordics and the Baltics?",
				"ecost": "Exceeding Cost",
				"dtpitduiteu": "Does the plan include the data usage in the EU?",
				"ntpwnbcitoftswadusage": "Note: This plan will not be considered in the optimization for the subscriptions with any data usage.",
				"epname": "Enter Plan Name",
				"ecprice": "Enter Cost Price",
				"pgroup":"Plan Group",
				"dtpitdSMSitnatbaltics": "Does the plan include the SMS usage in the Nordics and the Baltics?",
				"dtpitSMSuiteu": " Does the plan include the SMS usage in the EU?",
				"dtpitdmitnatbaltics": "Does the plan include the Voice usage in the Nordics and the Baltics?",
				"dtpitmuiteu": " Does the plan include the Voice usage in the EU?",
				"pspecification": "Plan Specification",
				"general": "General",
				"csplan": "Customer Specific",
				"datatransperspeed":"Data Transfer Speed"
			},

			"pgroups":{
				"name": "Plan Group Name",
				"pgroups": "Plan Groups",
				"pgnirequired": "Plan group name is required",
				"oirequired": "Operator is required"
			},

			"pgapgroup":{
				"name": "Plan Group Name",
				"epgname": "Enter plan group name",
				"pgnirequired": "Plan Group Name is required",
				"apgroup": "Add Plan Group",
				"oirequired": "Operator is required",
				"dywtatpgroup": "Do you want to Activate this plan group?"
			},

			"spnumber":{
				"spnumber": "Skipped Phone Number",
				"number": "Number",
				"enumber":"Enter Number",
				"apntbskipped": "Add Phone Number to be skipped",
				"pnumber": "Phone Number ",
				"pnirequired": "Phone Number is required!",
				"nirequired": "Number is required"
			},

			"help":{
				"help": "Help",
				"helpfile": "Help Files",
				"role": "Role",
				"srole": "Select Role",
				"page": "Page",
				"spage": "Select Page",
				"language":"Language"
			},

			"clientbillingplan": {
				"clientbillingplan": "Client Billing Plans",
				"name": "Name",
			},

			"addclientbillingplan": {
				"addcbillingplan": "Add Client Billing Plan",
				"editcbillingplan": "Edit Client Billing Plan",
				"name": "Name",
				"ename": "Enter Name",
				"rname": "Name is required!",
				"clength": "Contract Length",
				"slength": "Select Contract Term",
				"rclength": "Contract Length is required!",
				"apaymentdis": "Advance Payment Discount %",
				"eapaymentdis": "Enter Advance Payment Discount",
				"rapaymentdis": "Advance Payment Discount is required!",
				"punit": "Cost Per Unit",
				"units": "Units",
				"total": "Total",
				"eunit": "Enter Cost Per Unit Fee",
				"runit": "Cost Per Unit Fee is required!",
				"eunits": "Enter Units",
				"runits": "Unit is required!",
				"supfee": "Set Up Fee",
				"esupfee": "Enter Cost Per Unit Set Up Fee",
				"rsupfee": "Cost Per Unit Set Up Fee is required!",
				"sfpcompany": "Set up Fee Per Company",
				"esfpcompany": "Enter Cost Per Unit Set Up Fee Per Company",
				"rsfpcompany": "Cost Per Unit Set up Fee Per Company is required!",
				"nofPlan": "Cost Per Plan",
				"nofadditionaluser": "Additional Users fee",
				"enofadditionaluser": "Enter Cost Per Unit No. of Additional Users fee",
				"rnofadditionaluser": "Cost Per Unit No. of Additional Users Fee is required!",
				"aiupload": "Automated Invoice Upload fee",
				"eaiupload": "Enter Cost Per Unit Automated Invoice Upload fee",
				"raiupload": "Cost Per Unit Automated Invoice Upload Fee is required!",
				"ondsupport": "On-demand Support fee",
				"eondsupport": "Enter Cost Per Unit On-demand Support Fee",
				"rondsupport": "Cost Per Unit On-demand Support Fee is required!",
				"badminfee": "Bidgate Admin Fee",
				"ebadminfee": "Enter Cost Per Unit Bidgate Admin Fee",
				"rbadminfee": "Cost Per Unit Bidgate Admin Fee is required!",
				"cmpau": "Company Additional Users",
				"ecmpauser": "Enter Cost Per Unit Company Additional Users fee",
				"rcmpau": "Cost Per Unit Company Additional Users Fee is required!",
			},

			"ostatistics":{
				"optimization": "Optimization",
				"ostatistics": "Optimization Statistics",
				"usdetails": "User Subscription Details",
				"idate": "Invoice Date",
				"ucount": "Total User Count",
				"ciamount": "Invoice Amount",
				"oiamount": "Optimizable Invoice Amount",
				"olist": "Optimized Plan",
				"uoplan": "Un-Optimized Plan",
				"uuplan": "Un-Used Plan",
				"boptimization": "Cost Before Optimization",
				"savings": "Savings",
				"mfoptimization": "Mark for Optimization",
				"name": "Name",
				"pnumber": "Phone number",
				"cplan ": "Current Plan ",
				"camount": "Current Amount",
				"ebplan": "Optimized Plan",
				"ebpamount": "Optimized Plan Amount (€)",
				"dbplan": "DNA's Pest Plan",
				"dbpamount": "DNA's Best Plan Amount (€)",
				"tbplan": "Telia's Best Plan",
				"tbpamount": "Telia's Best Plan Amount (€)",
				"ddetails": "Domestic Details",
				"originals": "Originals",
				"forecast": "Forecast",
				"considered": "Considered",
				"sms": "SMS",
				"minutes": "Minutes",
				"data": "Data",
				"ndetails": "Nordic/Baltic Details",
				"eudetails": "EU Details",
				"bpamount": "Plan List Price",
				"pcprice": "Plan Contract Price",
				"saopamount": "SMS Add-On Contract Price",
				"tamount": "Total Amount: ",
				"oucount": "Optimized User Count",
				"tovalue": "Total Optimized Amount",
				"scname": "Sub-Company Name",
				"inumber": "Invoice Number",
				"sLimit": "Saving Limit",
				"apply": "Apply",
				"UnusedMonthCount":"Unused Month Count",
				"reoptimize_title":"Re-Optimize : Warning",
				"reoptimize_btn_ok":"Re-Optimize",
				"reoptimize_btn_close":"Close",
				"reoptimize_msg":"Are you sure you want to Re-Optimize? <br>Please perform this Re-Optimization only in case of change is Plans contract Prices.",
				"csavings": "Savings (€/mo.)"
			},

			"bpgroup":{
				"bpgroup": "Base Plan Group",
				"bpgroups": "Base Plan Groups",
				"anew": "Add New",
				"oname": "Operator Name",
				"ptype": "Plan Type",
				"soperator": "Select Operator",
				"ccategory": "Cost Category",
				"acategorization": "Automatic Categorization",
				"action": "Action",
				"oirequired": "Operator is required",
				"ptirequired": "Plan Type is required",
				"sptype": "Select Plan Type",
				"nirequired": "Name is required",
				"scctype": "Select Cost Category Type",
				"ccirequired": "Cost Category is required"
			},

			"abpgroup":{
				"abpgroup": "Add Base Plan",
				"afamandatory": "All Field(s) are mandatory.",
				"bgpname": "Base Plan Name",
				"nirequired": "Name is required",
				"operator": "Operator",
				"soperator": "Select Operator",
				"oirequired": "Operator is required",
				"sptype": "Select Plan Type",
				"sccategory": "Select Cost Category",
				"ccategory": "Cost Category",
				"ccirequired": "Cost Category is required!",
				"ptype": "Plan Type",
				"ptirequired": "Plan Type is required",
				"acategorization": "Automatic Categorization",
				"yes": "Yes",
				"no": "No",
				"save": "Save",
				"cancel": "Cancel",
				"ebpgname": "Enter Base Plan Name",
				"sbaseplangroup": "Select Base Plan Group",
				"bspirequired": "Base Plan Group is required!"
			},

			"mprofile":{
				"mprofile": "My Profile",
				"username": "Username",
				"fname": "First Name",
				"cno": "Contact No",
				"update": "Update",
				"efname": "Enter First Name",
				"eeid": "Enter Email ID",
				"epnumber": "Enter Phone Number",
				"scountry": "Select country",
				"fnirequired": "First name is required",
				"cnirequired": "Contact number is required",
				"cirequired": "Country is required",
				"eiiinvalid": "Email ID is invalid",
				"pnumber": "Phone Number",
				"cnsbdigit": "Contact number should be 8-10 digit",
				"euname": "Enter User Name"
			},

			"cpassword":{
				"cpassword": "Confirm Password",
				"opassword": "Old Password",
				"npassword": "New Password",
				"eopassword": "Enter Old Password",
				"enpassword": "Enter New Password",
				"ecpassword": "Enter Confirm Password",
				"opirequired": "Old Password is required",
				"npirequired": "New Password is required",
				"cpirequired": "Confirm Password is required"
			}
		},

		"auth": {
			"login":{
				"dhaaccount": "Don't have an account?",
				"sup": "Sign Up",
				"laccount": "Login Account",
				"email": "Email",
				"eiirequired": "Email ID is required",
				"eiiinvalid": "Email ID is invalid!",
				"password": "Password",
				"pirequired": "Password is required",
				"piinvalid": "Password is invalid",
				"pevcredential": "Please enter valid credential",
				"uinactivated": "User is not activated",
				"login": "Login",
				"epassword": "Enter Password",
				"eeid": "Enter Email ID",
				"inactiveuser": "User is currently inactive.",
				"operator": "Operator"
			},

			"register":{
				"sup": "Sign Up",
				"supbtn": "Sign Up",
				"eydtcyaccount": "Enter your details to create your account:",
				"back": "Back",
				"name": "Name",
				"ename": "Enter Name",
				"nirequired": "Name is required!",
				"cname": "Company Name",
				"ecname": "Enter Company Name",
				"cnamerequired": "Company Name is required!",
				"businessid": "Business ID",
				"ebusinessid": "Enter Business ID",
				"bidrequired": "Business ID is required!",
				"cno": "Contact Number",
				"ecno": "Enter Contact Number",
				"cnorequired": "Contact Number is required!",
				"cnoinvalid": "Contact Number is invalid!",
				"email": "Email",
				"eeid": "Enter Email ID",
				"eiirequired": "Email ID is required!",
				"eiiinvalid": "Email ID is invalid!",
				"rtype": "Register As",
				"company": "Company",
				"consultant": "Consultant",

			},

			"fpassword":{
				"rpmhbstyreid": "Reset password mail has been sent to your registered email id.",
				"eidnexists.": "Email id does not exist.",
				"fpassword": "	Forgot Password",
				"eyetrypassword": "Enter your email to reset your password:",
				"eiirequired": "Email ID is required!",
				"eiiinvalid": "Email ID is invalid!",
				"back": "Back",
				"eeid": "Enter Email ID",
				"submit": "Submit"
			},

			"rpassword":{
				"title": "Reset Your Password",
				"labelId": "Reset Password ID",
				"label": "Password",
				"placeholderP": "Enter Password",
				"reqErrMessage": "Password is required!",
				"pattErrMesssage": "Required at least one upper case letter, one number and one special character Ex:Pass@123",
				"labelCP": "Confirm Password",
				"placeholderCP": "Enter Confirm Password",
				"reqErrMessageCP": "Confirm password is required!",
				"notMatchErrMessageCP": "Password does not match",
				"messageInValid": "Please enter valid email",
				"submit": "Submit",
				"linkExpired":"Sorry, the password reset link has expired",
			},

			"capage":{
				"cuactivation": "Customer User Activation",
				"ciasuccessfully": "Customer is activated successfully"
			}
		},

		"client": {
			"cmanagement": {
				"cmanagement": "Client Management",
				"all":"All",
				"lead": "Lead",
				"company": "Company",
				"consultant": "Consultant",
				"hot": "Hot",
				"cold": "Cold",
				"lost": "Lost",
				"businessid": "Business ID",
				"bidgateaccountid": "BidGate ID",
				"companyname": "Company Name",
				"cadminname":"Company Admin Name",
				"cadmincontactno":"Company Admin Contact No.",
				"cadminEmail": "Company Admin Email",
				"adminname": "Admin Name",
				"admincontactno": "Admin Contact No.",
				"adminEmail": "Admin Email",
				"type": "Client Type",
				"admin": "Admin",
				"status": "Status",
				"active": "Active",
				"inactive": "Inactive",	
				"profile": "Profile",
				"assigncompany":"Assign Company",
				"subcompany":"Sub-Company",
				"addtionalsers":"Additional Users",
				"contractmanagement":"Contract Management",
				"billingmanagement":"Billing Management",
				"operatorcontractdetails":"Operator Contract Details",
				"clientpayment":"Client Payment",
				"clientonDemandsupport":"Client OnDemand Support",
				"clientInvoiceUserManagement":"Invoice User Management",
				"clientPlanDetails":"Plan Details"
			},

			"aclient": {
				"aclient": "Add Client",
				"eclient": "Edit Profile",
				"businessid": "Business ID",
				"ebusinessid": "Enter Business ID",
				"rbusinessid": "Business ID is required!",
				"baccountid": "BidGate ID",
				"ebaccountid": "Enter BidGate ID",
				"rbaccountid": "BidGate ID is required!",
				"lcname": "Lead Company Name",
				"elcname": "Enter Lead Company Name",
				"rlcname": "Lead Company Name is required!",
				"cname": "Company Name",
				"ecname":"Enter Company Name",
				"rcname":"Company Name is required!",
				"ccname": "Consultant Company Name",
				"eccname": "Enter Consultant Company Name",
				"rccname": "Consultant Company Name is required!",
				"address": "Address",
				"eaddress": "Enter Address",
				"raddress": "Address is required!",
				"country": "Country",
				"scountry": "Select Country",
				"rcountry": "Country is required!",
				"operator": "Operator",
				"soperator": "Select Operator",
				"roperator": "Operator is required!",
				"laname": "Lead Admin Name",
				"elaname": "Enter Lead Admin Name",
				"rlaname": "Lead Admin Name is required!",
				"cadminname": "Company Admin Name",
				"ecadminname": "Enter Company Admin Name",
				"rcadminname": "Company Admin Name is required!",
				"caname": "Consultant Admin Name",
				"ecaname": "Enter Consultant Admin Name",
				"rcaname": "Consultant Admin Name is required!",
				"password": "Password",
				"epassword": "Enter Password",
				"rpassword": "Password is required!",
				"cpassword": "Confirm Password",
				"ecpassword": "Enter Confirm Password",
				"rcpassword": "Confirm Password is required!",
				"pcriteria": "Required at least six characters, one upper case letter, one lower case letter, one number and one special character Ex: Pass@123",
				"pacpdnmatch": "Password and confirm password does not match.",
				"status": "Profile Status",
				"isterminated": "Profile Termination",
				"terminate": "Terminated",
				"active": "Active",
				"leadstate": "Lead Status",
				"hot": "Hot",
				"cold": "Cold",
				"lost": "Lost",
				"method": "Method",
				"invoiceuploadmethod": "Invoice Upload Method",
				"automation": "Automation",
				"manual": "Manual",
				"ReminderDate":"Reminder Date",
				"OperatorUsername": "Operator Portal Username",
				"OperatorPassword": "Operator Portal Password",
				"OperatorUsernameRequired": "Operator Portal username is required!",
				"OperatorPasswordRequired": "Operator Portal password is required!",
				"OperatorUsernameplace": "Enter Operator Portal username",
				"OperatorPasswordplace": "Enter Operator Portal password",
			},

			"vprofile": {
				"profile": "Profile",
				"rdate": "Registration Date",
				"resetpassword": "Reset Password",
				"ctocompany": "Convert to Company",
				"buissInfo": "Business Information",
				"adminInfo": "Admin Information",
				"statusInfo": "Status Information",
				"cmpInfo": "Company Information",
				"leadInfo": "Lead Company Information",
				"conscmpInfo": "Consultant Company Information",
				"startingPointSelect":"Starting Point",
				"notAvailable":"Not Available"

			},

			"iclient": {
				"iclient": "Interested Client",
				"businessid": "Business ID",
				"name": "Name",
				"cname": "Company Name",
				"cno": "Contact No.",
				"email": "Email ID",
				"ctype": "Client Type",
				"ishowndate": "Interest Shown Date",
				"status": "Status",
				"rdate": "Registration Date",
				"notconverted": "Not Converted",	
				"ctolead": "Converted To Lead",
				"ctocompany": "Converted To Company",
				"ctoconsultant": "Converted To Consultant",
				"convertlead": "Convert To Lead",
				"convertconsultant": "Convert to Consultant",
				"ClientTypeNameConsultant": "Consultant",
				"ClientTypeNameCompany": "Company",
			},

			"asscompany": {
				"asscompany": "Assign Company",
				"cname": "Consultant Name",
				"cmapping": "Company Mapping",
				"ascompany": "Assigned Company",
				"unascompany": "Unassigned Company"
			},

			"subcompany": {
				"smanagement": "Sub-Company Management",
				"sname": "Sub-company Name",
				"nouser": "No. Of Users",
				"billingplanid":"Billing Plan",
				"esname":"Enter Sub-Company Name",
				"rsname":"Sub-company Name is required!"
			},

			"aclientuser":{
				"umanagement": "User Management",
				"adduser": "Add User",
				"edituser":"Edit User",
				"uinfo":"User Information",
				"Name": "Name",
				"eName": "Enter Name",
				"rName": "Name is Required",
				"address": "Address",
				"eAddress": "Enter Address",
				"rAddress": "Address is Required",
				"econtactno": "Enter Contact No",
				"rcontactno": "Contact Number is required!",
				"icontactno": "Contact Number is invalid!",
				"email": "Email",
				"eemail": "Enter Email",
				"remail": "Email is required!",
				"iemail": "Email is invalid!",
				"country": "Country",
				"scountry": "Select Country",
				"rcountry": "Country is required!",
				"status": "Status",
				"active": "Active",
				"accesspermission": "Access Permission",
				"general": "General",
				"dashboards":"Dashboards",
				"subcompany": "Sub-Company",
				"ssubcompany": "Select a sub-company",
				"rsubcompany": "Sub-Company is required",
				"costcenter": "Cost Center",
				"scostcenter": "Select Cost Center",
				"rcostcenter": "Cost Center is required",
				"uploadinvoice": "Upload Invoice",
				"clientmanagement":"Client Management",
				"reports": "Reports",
				"costsummary": "Optimizable Cost Summaries",
				"usagesummary": "Optimizable Usages Summaries",
				"plantype": "Base Plan Types",
				"topuser": "Top Users",
				"OverChargedPlans": "Overcharged Plans",
				"clientcontracts":"Client Contracts",
				"masters": "Masters",
				"cmasters": "Client Masters",
				"invoiceuser": "Invoice User Management",
				"plandetails": "Plan Details",
				"contractdetails":"Operator Contract Details",
				"optimization": "Optimization",
				"optimizeall": "All (View + Export + Re-optimize + Mark for optimization)",
				"optimizeview": "View (View + Export)",
				"save": "Save",
				"update": "Update",
				"cancel": "Cancel",
				"operator": "Operator",
				"currency": "Currency",
				"costCenter": "Cost Center",
				"costGroup": "Cost Groups",
				"costCategory": "Cost Category",
				"costElement": "Cost Element",
				"plan": "Plan",
				"planGroup": "Plan Groups",
				"basePlanGroup": "Base Plan Groups",
				"skippedPhoneNumber": "Skipped Phone Number",
				"clientBillingPlan": "Client Billing Plan",
				"caddsuccess": "Client user added successfully!",
				"cupdatesuccess": "Client user updated successfully!",	
				"ConsultantBilling": "Consultant Billing",	
				"downloads": "Downloads",
				"unusedMonthCount":"Unused Month Count",
				"costAndUsage":"Cost And Usage",
				"savings":"Savings"			
			},
			
			"clientcontract":{
				"cmanagement":"Contract Management",
				"addContract":"Add New Contract",
				"tcontract": "Terminate Contract",
				"terminatemsg":"Are you sure you want to terminate this contract?",	
				"tdate":"Termination Date",
				"rtdate":"Termination date is required!",
				"viewcontract":"View Contract",
				"editcontract":"Edit Contract",
				"terminatecontract":"Terminate Contract",
				"terminationdateval":"Termination date should be greater than contract start date and less than contract end date!"
			},

			"viewclientcontract": {
				"vcontract": "View Contract",
			},

			"billingmanagement":{
				"billingmanagement":"Billing Management",
				"accdetails":"Account Details",
				"cdetails":"Contract Details",
				"sdate":"Contract Start Date",
				"edate":"Contract End Date",
				"billsummary":"Billing Summary",
				"ctype":"Charges Type",
				"amt":"Amount",
				"otcharges":"One time Charges",
				"rcharges":"Recurring Charges",
				"total":"Total",
				"bdetails":"Billing Details",
				"bperiod":"Billing Period",
				"units":"Units",
				"cpunits":"Cost Per Unit",
				"cost":"Cost",
				"mcost":"Monthly Cost",
				"ssufee":"Service Set Up Fee",
				"ssufpcompany":"Service Set Up Fee Per Company",
				"bafee":"Bidgate Admin Fee",
				"nofplans":"No. of Plans",
				"nofaddusers":"No. of Additional Users",
				"autoiupload": "Automated Invoice Upload",
				"ondsupport":"On-Demand Support",
				"advpaydisc":"Advance Payment Discount"
			},

			"addclientcontract": {
				"addcontract": "Add New Contract",
				"editcontract": "Edit Contract",
				"cterm":"Contract Term",
				"scterm":"Select Contract Term",
				"rcterm":"Contract Term is required!",
				"sdate":"Start Date",
				"rstartdate":"Start Date is required!",
				"minstartdateval":"Start Date should be greater than previous contract end date!",
				"edate":"End Date",
				"redate":"End date is Required",
				"inactivedate":"Inactive Date",
				"bcycle": "Billing Cycle",
				"sbcycle": "Select Billing Cycle",
				"rbcycle": "Billing Cycle is required!",
				"cbillingPlan": "Client Billing Plan",
				"scbillingPlan": "Select Client Billing Plan",
				"rcbillingPlan": "Client Billing Plan is required!",
				"nofplans": "No. of Plans",
				"snofplans": "Select No. of Plans",
				"units": "Units",
				"cpunit": "Cost Per Unit",
				"mcost": "Monthly Cost",
				"ycost": "Yearly Cost",
				"eunit": "Enter Units",
				"total":"Total",	
				"sadraft":"Save as Draft",
				"unitval": "Unit should be 0 or 1.",
				"enoplanspu":"Enter Cost Per Unit No. of Plans",
				"rnoplanspu":"Cost Per Unit No. of Plans is required!",
				"ecpadvancediscount":"Enter Cost Per Unit Advance Payment Discount",
				"rcpadvancediscount":"Cost Per Unit Advance Payment Discount is required!",
				"costperplans":"Cost Per Plan",
				"CompanyAdditionalUser":"Company Additional User",
				"pCompanyAdditionalUser":"Enter Cost Per Unit Company Additional User",
			},

			"contractdetails": {
				"contractdetails": "Operator Contract Details ",
				"scpname": "Operator Sales Contact Person Name",
				"escpname": "Enter Operator Sales Contact Person Name",
				"rscpname": "Operator Sales Contact Person Name is required!",
				"uireminder": "Upload Invoice Reminder",
				"ruireminder": "Upload Invoice Reminder is required!",
				"ocsdate": "Operator Contract Start Date",
				"rocsdate": "Operator Contract Start Date is required!",
				"ocedate": "Operator Contract End Date",
				"rocedate": "Operator Contract End Date is required!",
				"minvdate": "Date Should be greater than Operator Contract Start Date!",
				"maxvdate": "Date Should be less than Operator Contract End Date!",
				"cedreminder": "Contract End Date Reminder",
				"rcedreminder": "Contract End Date Reminder is required",
				"undocument": "Upload Document",
				"acdetails": "Add Contract Details",
				"ecdetails": "Edit Contract Details",
				"uploadnewdocument":"Upload New Document",
				"dname": "Document Name",
				"edname": "Enter Document Name",
				"rdname": "Document Name is required!",
				"upload": "Upload",
				"udate": "Uploaded Date",
				"idocument": "Invalid file format!",
				"sfileformat": "Allowed file types: .doc, .docx, .xls, .xlsx, .csv, .pdf.",
				"docnameval": "Document name must be alphanumeric only and should not contains any symbols or special characters.",
				"sfile": "Select file",
			},

			"clientpayment": {
				"payment":"Payments",
				"paymentdate": "Payment Date",
				"description": "Description",
				"edescription": "Enter Description",
				"amount": "Amount",
				"eamount": "Enter Amount",
				"add": "Add Payment",
				"rpaymentdate": "Payment Date is required!",
				"mpaymentdate":"Payment date should not be future date!",
				"rdescription": "Description is required!",
				"ramount": "Amount is required!",
			},

			"clientOnDemandSupport": {
				"ondemandsupport":"OnDemand Support",
				"addondemandsupport":"Add OnDemand Support",
				"onDemandSupportdate": "OnDemand Support Date",
				"description": "Description",
				"edescription": "Enter Description",
				"hours": "Hours",
				"ehours": "Enter Hours",
				"ronDemandSupportdate": "OnDemand Support Date is required!",
				"dateval":"OnDemand Support Date should be current month date and not greater than today date!",
				"rdescription": "Description is required!",
				"rhours": "Hours is required!",
			}
		}
	}
};
