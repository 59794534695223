import { DOCUMENT } from '@angular/common';
import { Inject } from "@angular/core";
import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding, OnInit, ViewChild, } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';

import * as objectPath from 'object-path';

import { filter } from 'rxjs/operators';

import { SplashScreenService } from './core/services/splash-screen.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { ClassInitService } from './core/services/class-init.service';
import { PageConfigService } from './core/services/page-config.service';

import { TranslationService } from './core/services/translation.service';
import { locale as enLang } from './config/i18n/en';
import { locale as fnLang } from './config/i18n/fn';
import { environment } from '../environments/environment';
@Component({
	selector: 'body[m-root]',
	templateUrl: './app.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class AppComponent implements AfterViewInit, OnInit {
	
	@HostBinding('style') style: any;
	@HostBinding('class') classes: any = '';

	@ViewChild('splashScreen', { read: ElementRef })

	splashScreen: ElementRef;
	splashScreenImage: string;
	constructor(
		private sanitizer: DomSanitizer,
		private router: Router,
		private splashScreenService: SplashScreenService,
		private layoutConfigService: LayoutConfigService,
		private classInitService: ClassInitService,
		private pageConfigService: PageConfigService,
		private translationService: TranslationService,
		
		@Inject(DOCUMENT) private document: Document
	) 
	{	
		environment.clientDetails.token = localStorage.getItem('token');
		environment.clientDetails.token_type = localStorage.getItem('token_type');
		environment.clientDetails.fullName = localStorage.getItem('fullName');
		environment.clientDetails.emailID = localStorage.getItem('emailID');
		environment.clientDetails.roleID = Number(localStorage.getItem('roleID'));
		environment.clientDetails.languageID = Number(localStorage.getItem('languageID'));
		environment.clientDetails.logo = localStorage.getItem('logo');		
		environment.clientDetails.applicationThemeID = Number(localStorage.getItem('applicationThemeID'));
		environment.clientDetails.basePath = localStorage.getItem('basePath');
		

		this.classInitService.onClassesUpdated$.subscribe(classes => {			
			setTimeout(() => this.classes = classes.body.join(' '));
		});

		this.layoutConfigService.onLayoutConfigUpdated$.subscribe(model => {
			this.classInitService.setConfig(model);

			this.style = '';
			if (objectPath.get(model.config, 'self.layout') === 'boxed') {
				const backgroundImage = objectPath.get(model.config, 'self.background');
				if (backgroundImage) {
					this.style = this.sanitizer.bypassSecurityTrustStyle('background-image: url(' + objectPath.get(model.config, 'self.background') + ')');
				}
			}

			this.splashScreenImage = objectPath.get(model.config, 'loader.image');
		});

		this.translationService.loadTranslations(enLang, fnLang);

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				this.layoutConfigService.setModel({ page: objectPath.get(this.pageConfigService.getCurrentPageConfig(), 'config') }, true);
		});
	}

	ngOnInit(): void {
		if(environment.clientDetails.applicationThemeID == 1) 
				this.loadStyle('CSS_Bidgate.css');
			else if(environment.clientDetails.applicationThemeID == 2)
				this.loadStyle('CSS_StyleSheet_01.css');
			else if(environment.clientDetails.applicationThemeID == 3)
				this.loadStyle('CSS_StyleSheet_02.css');
			else if(environment.clientDetails.applicationThemeID == 4)
				this.loadStyle('CSS_StyleSheet_03.css');
			else if(environment.clientDetails.applicationThemeID == 5)
				this.loadStyle('CSS_StyleSheet_04.css');
			else if(environment.clientDetails.applicationThemeID == 6)
				this.loadStyle('CSS_StyleSheet_05.css');
			else if(environment.clientDetails.applicationThemeID == 7)
				this.loadStyle('CSS_StyleSheet_07.css');
			else
				this.loadStyle('CSS_Bidgate.css');

			if(environment.clientDetails.languageID == 1)
				this.translationService.setLanguage('en');
			else if(environment.clientDetails.languageID == 2)
				this.translationService.setLanguage('fn');
			else
				this.translationService.setLanguage('en');
	 }

	ngAfterViewInit(): void {
		if (this.splashScreen) 
			this.splashScreenService.init(this.splashScreen.nativeElement);		
	}
    
	loadStyle(styleName: string) {
		const head = this.document.getElementsByTagName('head')[0];
		
		let themeLink = this.document.getElementById('client-theme') as HTMLLinkElement;
		
	    if (themeLink) {
			themeLink.href = styleName;
		} else {
	   		const style = this.document.createElement('link');
	   		style.id = 'client-theme';
	   		style.rel = 'stylesheet';
	   		style.href = `${styleName}`;
	   		head.appendChild(style);
	 	}
	}
}
