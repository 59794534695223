import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientAuthenticationService } from './client-authentication.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {

    constructor(public router: Router, private spinner: NgxSpinnerService, private clientAuthentication: ClientAuthenticationService) 
    { 
        environment.clientDetails.token = localStorage.getItem('token');           	
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.spinner.show();
        
        if(environment.clientDetails.token != "" && environment.clientDetails.token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${environment.clientDetails.token}`
                }
            });
        }        

        return next.handle(request).pipe(
            tap(
                event => {
                    if (event instanceof HttpResponse) {
                      this.spinner.hide();
                    }
                },
                error => {
                    this.spinner.hide();
                    if (error.status === 401) {
                        if(environment.clientDetails.token != "" && environment.clientDetails.token != null)
                            alert("Your session has expired, please login again");

                        this.clientAuthentication.logout();                        
                    }    
                }
            )
        );
    }
}
