import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from } from "rxjs";
import { Credential, ReSetPasswordModel } from "../models/model";
import { environment } from '../../../../environments/environment';

@Injectable()
export class ClientAuthenticationService {

	errorresponse: any;

	baseUrl = environment.baseUrl;
	tokenUrl = environment.tokenUrl;

	API_URL = 'api';
	API_ENDPOINT_LOGIN = '/token';

	constructor(private http: HttpClient, private _router: Router) {
	}

	SignUp(parameter) {
		return this.http.post(this.baseUrl + 'Account/Signup', parameter, { observe: 'response' })
	}
	
	// Added/Modified By Gaurav Kakad - Multiple Operator
	login(credential: Credential): Observable<any> {
		var str = "grant_type=password&username=" + encodeURIComponent(credential.Email) +
		 "&password=" + encodeURIComponent(credential.Password) +
		 "&OperatorId=" + encodeURIComponent(credential.OperatorId);	
		return this.http.post(this.tokenUrl, str, { observe: 'response' });
	}

	logout() {
		environment.clientDetails =
		{
			"token": "",
			"token_type": "",
			"fullName": "",
			"emailID": "",
			"roleID": 0,
			"languageID": 0,
			"logo": "",
			"applicationThemeID": 0,
			"basePath": "",
			"acessPermissions": [],
			"loggedInOperatorName":"",
			"loggedInOperatorID":0
		}

		localStorage.clear();

		this._router.navigate(["/login"]).then(() => {
			window.location.reload();
		});;
	}

	GetClientInfo(): Observable<any> {
		return this.http.get(this.baseUrl + 'Account/GetClientInfo', { observe: 'response' })
	}

	public handleError<T>(operation = 'operation', result?: any) {
		return (error: any): Observable<any> => {

			this.errorresponse = error;

			if (error.status = 0) {
				localStorage.clear();
				this._router.navigate(['/login']);
			}

			if (error.status = 401) {
				localStorage.clear();
				this._router.navigate(['/login']);
				return error.status;
			}
			else if (error.error["error"] = "invalid_grant") {
				console.error("error", error.error["error"]);
			}
			else {
				return null;
			}

			return from(result);
		};
	}

	ForgotPassword(email: any) {
		return this.http.get(this.baseUrl + 'Account/ForgotPassword?email=' + email, { observe: 'response' });
	}

	ReSetPassword(reSetPasswordModel: ReSetPasswordModel) {
		return this.http.post(this.baseUrl + 'Account/ReSetPassword', reSetPasswordModel, { observe: 'response' });
	}
}
