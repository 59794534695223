import { ConfigModel } from '../core/interfaces/config';

export class MenuBidgateConfig implements ConfigModel {
	public config: any = {};
 
	constructor() {
		this.config = {			
			aside: {
				self: {},
				items: [					
					{
						title: 'Dashboard',	
						bullet: "dot",
						icon: 'flaticon-line-graph',
						translate: 'admin.dashboard.dashboard',
						page: '/admin/dashboard',
						root: true,
						submenu:[
							{
								title: 'Cost And Savings',	
								translate: 'admin.dashboard.costAndUsage',
								page: '/admin/dashboard/costAndUsage',
								root: true
												
							},
							{
								title: 'Savings',	
								translate: 'admin.dashboard_2.dashboard_2',
								page: '/admin/dashboard/savings',
								root: true				
							}
						]				
					},					
					{
						title: 'Upload Invoice',						
						icon: 'flaticon-upload',						
						translate: 'admin.uinvoice.uinvoice',
						page: '/admin/invoice-upload',
						root: true						
					},
					{
						title: 'Reports',
						bullet: "dot",
						icon: 'flaticon-interface-7',											
						translate: 'ckeywords.reports',	
						page:"/admin/reports",																	
						root: true,				
						submenu: [
							{
								title: 'Optimizable Cost Summaries',																							
								translate: 'admin.cseowise.csummaries',																
								page: '/admin/reports/cost-summaries',
								root: true								
							},
							{
								title: 'Optimizable Usages Summaries',								
								translate: 'ckeywords.usummaries',																
								page: '/admin/reports/usage-summaries',
								root: true							
							},
							{
								title: 'Base Plan Types',								
								translate: 'admin.plantypereport.plantype',
								page: '/admin/reports/base-plan-types',
								root: true
							},
							{
								title: 'Top Users',								
								translate: 'admin.topusersreport.tusers',
								page: '/admin/reports/top-users',
								root: true
							},
							{
								title: 'Overcharged Plans',								
								translate: 'admin.overchargedplansreport.overchargedplans',
								page: '/admin/reports/overcharged-plans',
								root: true
							},
							{
								title: 'Client Contracts',								
								translate: 'admin.clientcontractsreport.clientcontracts',
								page: '/admin/reports/client-contracts',
								root: true
							},
							{
								title: 'Activity Log',								
								translate: 'admin.activitylog.activitylog',
								page: '/admin/reports/activity-log',
								root: true
							},
							{
								title: 'Consultant Billing',								
								translate: 'admin.consultantbilling.consultantbilling',
								page: '/admin/reports/consultant-billing',
								root: true
							},
							{
								title: 'Downloads',								
								translate: 'admin.scheduleddownloads.downloads',
								page: '/admin/reports/downloads',
								root: true
							},
							{
								title: 'Failed Invoice Download',								
								translate: 'admin.failedInvoice.failedInvoiceDownload',
								page: '/admin/reports/failed-invoice-download',
								root: true
							},
							{
								title: 'Full Automation Reports',								
								translate: 'admin.fullAutomation.fullAutomation',
								page: '/admin/reports/full-automation',
								root: true
							},
							{
								title: 'Unused Month Count Report',								
								translate: 'admin.unusedMonthCount.unusedMonthCountTitle',
								page: '/admin/reports/Unused-Month-Count',
								root: true
							}
						]
					},					
					{
						title: 'Masters',
						bullet: "dot",
						icon: "flaticon-layers",
						translate: 'ckeywords.masters',	
						page: '/admin/master',
						root: true,																
						submenu: [
							{
								title: 'Operators',	
								translate: 'admin.operators.operators',
								page: '/admin/master/operators'
							},
							{
								title: 'Country',		
								translate: 'ckeywords.country',
								page: '/admin/master/country'
							},
							{
								title: 'Currency',																
								translate:"admin.currency.currency",
								page: '/admin/master/currency'
							},
							{
								title: 'Cost Centers',		
								translate: 'ckeywords.ccenter',
								page: '/admin/master/cost-centers'
							},
							{
								title: 'Cost Groups',	
								translate: 'admin.cgroup.cgroups',
								page: '/admin/master/cost-groups'
							},
							{
								title: 'Cost Category',
								translate: 'admin.ccategory.ccategory',
								page: '/admin/master/cost-category'
							},
							{
								title: 'Cost Elements',					
								translate: 'admin.celements.celements',
								page: '/admin/master/cost-elements'
							},
							{
								title: 'Plan',
								translate:"admin.plan.plan",
								page: '/admin/master/plan'
							},
							{
								title: 'Plan Groups',
								translate:"admin.pgroups.pgroups",
								page: '/admin/master/plan-groups'
							},
							{
								title: 'Base Plan Group',								
								translate:"admin.bpgroup.bpgroups",
								page: '/admin/master/base-plan-groups'
							},
							{
								title: 'Skipped Phone Number',
								translate:"admin.spnumber.spnumber",
								page: '/admin/master/skipped-phone-number'
							},
							{
								title: 'Client Billing Plan',
								translate:"admin.clientbillingplan.clientbillingplan",
								page: '/admin/master/client-billing-plan'
							},
							{
								title: 'Help',
								translate:"admin.help.help",
								page: '/admin/master/help'
							},
							// {
							// 	title: 'Client Masters',
							// 	bullet: "dot",								
							// 	translate:'ckeywords.cmasters',
							// 	page: '/admin/client-masters',
							// 	root: true,
							// 	submenu: [
							// 		{
							// 			title: 'Invoice User Management',										
							// 			translate: "customer.umanagement.umanagement",
							// 			page: '/admin/master/invoice-user-management'
							// 		},
							// 		{
							// 			title: 'Plan Details',										
							// 			translate: "customer.pdetails.pdetails",
							// 			page: '/admin/master/plan-details'
							// 		},	
							// 		// {
							// 		// 	title: 'Contract Details',										
							// 		// 	translate: "client.contractdetails.contractdetails",
							// 		// 	page: '/admin/master/contract-details'
							// 		// }									
							// 	]
							// }
						]
					},
					{
						title: 'Client Management',
						icon: 'flaticon-map',
						translate: "client.cmanagement.cmanagement",
						page: '/client-management/client-management',
						root: true
					},
					{
						title: 'Optimization',
						icon: 'flaticon-graphic-2',
						translate: "admin.ostatistics.optimization",
						page: '/admin/invoice-optimization',						
						root: true					
					}
				]
			}
		}
	}
}
