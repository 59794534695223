import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import { environment } from '../environments/environment';
import { ClientAuthenticationService } from './content/pages/auth/client-authentication.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  public accessPermission: any;

  constructor(private _router: Router, private clientAuthentication: ClientAuthenticationService) 
  { 
    environment.clientDetails.token = localStorage.getItem('token');
    environment.clientDetails.roleID = Number(localStorage.getItem('roleID'));
    environment.clientDetails.basePath = localStorage.getItem('basePath');
    environment.clientDetails.acessPermissions = JSON.parse(localStorage.getItem('acessPermissions'));      
  }

  canActivate(
    next: ActivatedRouteSnapshot,

    state: RouterStateSnapshot): boolean {

    if (environment.clientDetails.token != "" && environment.clientDetails.token != null) 
    { 
      let roles = next.data["roles"] as Array<number>;
      
      if (roles) {        

        if (environment.clientDetails.roleID != 0) {

          if (roles.includes(environment.clientDetails.roleID)) 
          {
            if(!environment.clientDetails.acessPermissions[state.url])
              {
                this._router.navigate([environment.clientDetails.basePath]);
                return false;
              }            
              else
                return true;            
          }           
          else{
            this._router.navigate([environment.clientDetails.basePath]);
            return false; 
          } 
                
        }
        else {
          this.clientAuthentication.logout();
  
          return false;
        }
      }
      else
        return true;
    }

    this._router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    
    return false;    
  }
}
