import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Router, NavigationStart } from '@angular/router';
import { MenuBidgateConfig } from '../../config/bidgate-menu';
import { MenuCompanyConfig } from '../../config/company-menu';
import { MenuConsultantConfig } from '../../config/consultant-menu';

@Injectable()
export class MenuConfigService {

	//MenuBidgateConfig		
	public configModelBidgate: MenuBidgateConfig = new MenuBidgateConfig();
	public onMenuUpdatedBidgate: BehaviorSubject<MenuBidgateConfig> = new BehaviorSubject(this.configModelBidgate);
	menuHasChangedBidgate: any = false;

	//MenuCompanyConfig
	public configModelCompany: MenuCompanyConfig = new MenuCompanyConfig();
	public onMenuUpdatedCompany: BehaviorSubject<MenuCompanyConfig> = new BehaviorSubject(this.configModelCompany)
	menuHasChangedCompany: any = false;

	//MenuConsultantConfig
	public configModelConsultant: MenuConsultantConfig = new MenuConsultantConfig();
	public onMenuUpdatedConsultant: BehaviorSubject<MenuConsultantConfig> = new BehaviorSubject(this.configModelConsultant)
	menuHasChangedConsultant: any = false;

	constructor(private router: Router) {

		//Bidgate
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {				
				if (this.menuHasChangedBidgate) {
					this.resetModelBidgate();
				}				
			});

		//Company
		this.router.events
			.pipe(filter(event => event instanceof NavigationStart))
			.subscribe(event => {
				if (this.menuHasChangedCompany) {
					this.resetModelCompany();
				}
			});

		//Consultant
		this.router.events
		.pipe(filter(event => event instanceof NavigationStart))
		.subscribe(event => {
			if (this.menuHasChangedConsultant) {
				this.resetModelConsultant();
			}
		});
	}

	//Bidgate
	setModelBidgate(menuBidgateModel: MenuBidgateConfig) {
		this.configModelBidgate = Object.assign(this.configModelBidgate, menuBidgateModel);
		this.onMenuUpdatedBidgate.next(this.configModelBidgate);
		this.menuHasChangedBidgate = true;
	}

	resetModelBidgate() {
		this.configModelBidgate = new MenuBidgateConfig();
		this.onMenuUpdatedBidgate.next(this.configModelBidgate);
		this.menuHasChangedBidgate = false;
	}

	//Company
	setModelCompany(menuModelCompany: MenuCompanyConfig) {
		this.configModelCompany = Object.assign(this.configModelCompany, menuModelCompany);
		this.onMenuUpdatedCompany.next(this.configModelCompany);
		this.menuHasChangedCompany = true;
	}

	resetModelCompany() {
		this.configModelCompany = new MenuCompanyConfig();
		this.onMenuUpdatedCompany.next(this.configModelCompany);
		this.menuHasChangedCompany = false;
	}

	//Consultant
	setModelConsultant(menuModelConsultant: MenuConsultantConfig) {
		this.configModelConsultant = Object.assign(this.configModelConsultant, menuModelConsultant);
		this.onMenuUpdatedConsultant.next(this.configModelConsultant);
		this.menuHasChangedConsultant = true;
	}

	resetModelConsultant() {
		this.configModelConsultant = new MenuConsultantConfig();
		this.onMenuUpdatedConsultant.next(this.configModelConsultant);
		this.menuHasChangedConsultant = false;
	}
}
