import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';

import { AppRoutingModule } from './app-routing.module';

import { SplashScreenService } from './core/services/splash-screen.service';
import { LayoutConfigService } from './core/services/layout-config.service';
import { LayoutRefService } from './core/services/layout/layout-ref.service';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { ClassInitService } from './core/services/class-init.service';
import { PageConfigService } from './core/services/page-config.service';
import { HeaderService } from './core/services/layout/header.service';
import { MenuAsideService } from './core/services/layout/menu-aside.service';
import { MenuConfigService } from './core/services/menu-config.service';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthGuard } from './auth.guard';
import { Interceptor } from './content/pages/auth/intercept.service';

import { AppComponent } from './app.component';

import { ClientAuthenticationService } from './content/pages/auth/client-authentication.service';

import { TranslateModule } from '@ngx-translate/core';

import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import fr from '@angular/common/locales/fr';

import { DialogModule } from '@ngneat/dialog';
registerLocaleData(en);
registerLocaleData(fr);

@NgModule({
	declarations: [AppComponent],
	imports: [			
		CommonModule,
		FormsModule,		

		BrowserModule,
		BrowserAnimationsModule,	
		MatProgressSpinnerModule,
		
		AppRoutingModule,

		TranslateModule.forRoot(),
		
		HttpClientModule,
		DialogModule.forRoot({
			backdrop: true,
			resizable: true,
			draggable: true,
		  })
	],
	providers: [
		ClientAuthenticationService,

		SplashScreenService,
		LayoutConfigService,
		LayoutRefService,
		LayoutConfigStorageService,
		ClassInitService,
		PageConfigService,
		HeaderService,
		MenuAsideService,
		MenuConfigService,

		AuthGuard,		
		{
			provide: HTTP_INTERCEPTORS,			
			useClass: Interceptor,
			multi: true
		},		
		{ provide: NZ_I18N, useValue: en_US },
	],
	bootstrap: [AppComponent]
})

export class AppModule { }